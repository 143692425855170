.button {
	padding: 0 8px;
	border: 1px solid #024775;
	background-color: $color-primary-blue;
	box-shadow: inset 0 1px 0 0 rgba(255,255,255,0.3);
	color: #fff;

	&:hover {
		background-color: darken($color-primary-blue, 5%);
	}

}
.light-button {
	display: inline-block;
	padding: em(11);
	border: 1px solid $color-primary-blue;
	border-radius: 2px;
	box-shadow: none;
	line-height: 1;
	text-decoration: none;

	&.hollow {
		background-color: #fff;
		color: $color-primary-blue;
	}

	&:hover {
		background-color: #dbebfb;
		border-color: #dbebfb;
		color: $color-primary-blue;
	}

	&.hollow:hover {
		border-color: $color-primary-blue;
	}

}
