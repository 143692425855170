/* Overrides and customizations for quick fixes by Triquanta */
@import 'inc/colors';
@import 'inc/buttons';

div.tiles {
  // Negative left margin correction. Needed because all tiles have the same
  // left and right margin.
  margin: 0 0 1.5em -0.625em;
  font-size: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

.shortcuts-anchor {

  &.icon-cog:before {
    content: '\F013';
  }

}

.tile p img {
  border: none;
}

.page-compliant-pakketten .ui-module-header > .header-intro,
.page-compliant-pakketten .ui-module-header > h2 {
  display: block;
}

.rating-table tr.active th,
.rating-table tr.active td {
  color: $color-primary-orange;
}

.ui-table-right {
  min-width: 100px;
}

.views-field-gemma-state,
.views-field-municipalities,
.views-field-reference-components,
.views-field-reference-components-facet,
.views-field-supplier-node,
.views-field-software-product {
  width: 18%;
}
.views-field-software-product-version-text {
  width: 25%;
}

#edit-generator .prettyform-btn {
  margin-right: 10px;
}

#modal-content .prettyform {
  min-width: 1024px;
}
.ui-accordion-progress {
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  width: auto;
  margin-top: -13px;
}

.ui-accordion-progress-label {
  line-height: 22px !important;
  margin: 3px 8px 0 8px;
  .fa-certificate {
    top: -2px;
    margin-left: 5px;
  }
}

.table-norms {
  table-layout: fixed;
  td:last-child {
    a {
      display: block;
      margin-bottom: 5px;
      word-wrap: break-word;
    }
  }
  td:first-child {
    width: 25%;
  }
}

.dashboard-section.s-search {
  overflow: hidden;
  -webkit-transition: max-height 0.8s;
  -moz-transition: max-height 0.8s;
  transition: max-height 0.8s;
  max-height: 200px;
  padding: 0;
  &.closed {
    max-height: 0;
  }
  .dashboard-contained {
    margin: 2em auto 2.5em auto;
  }
}

.dashboard-section.s-shortcuts {
  margin-top: 2em;
  &:after {
    content: ' ';
    display: table;
    clear: both;
  }
}

.block-swc-dashboards {
  img{
    max-width: 100%;
    display: block;
  }
  .tile-heading {
    width: 85%;
    line-height: 1.5em;
    padding-right: 0.2em;
    margin-top: 0.25em;
    position: relative;
  }
  &.block-swc-dashboards-with-subject .tile-header,
  &.block-swc-dashboards-with-text .tile-content,
  &.block-swc-dashboards-with-text .tile-actions {
    background-color: rgba(255, 255, 255, 0.8);
  }
  &.block-swc-dashboards-with-background {
    border-radius: 3px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    .tile-body {
      border-radius: 0;
    }
  }
  .tile-actions {
    -webkit-flex: 0 0 3.5em;
    -moz-flex: 0 0 3.5em;
    -ms-flex: 0 0 3.5em;
    flex: 0 0 3.5em;
  }

  // Frontpage non flexbox support fallback.
  .front & {
    .tile-heading {
      min-height: 47px;
      > div {
        z-index: 1;
      }
    }
    .tile-content {
      min-height: 270px;
    }
    .tile-actions {
      min-height: 55px;
    }
  }
}

.suggestion_intro {
  margin-top: 2em;
}

.section-referentiecomponent .gemma-catalog .ui-module h5 {
  font-size: 16px;
  padding-left: 28px;
}

.page-swc-forms table {
  font-size: 1.4rem;
}

.page-swc-forms input[type="submit"],
.modal-content input[type="submit"] {
  padding: .5em 1em;
  border-width: 1px;
  background-color: #0078c8;
  border-color: #0078c8;
  border-radius: 2px;
  box-shadow: none;
  &:hover {
    background-color: #0069af;
  }
  &.ui-btn-danger {
    background-color: #b94a48;
    border-color: #b94a48;
    &:hover {
      background-color: #b90f09;
    }
  }
}
.form-item-added-norms-selector {
  margin: 1em 1.5em;
}
.form-error .chosen-container {
  border:solid 2px red;
}

#norm-elements {
  .form-type-link-field {
    margin-bottom: 0;
  }
}

div.ctools-modal-content .form-item label {
  width: auto;
  float: none;
}

.dashboard-section .tooltip {
  padding-right: 1em;
  span {
    font-size: 14px;
    white-space: normal;
    right: 20px;
    top: 70px;
  }
  i {
    margin-left: 0.5em;
    background-color: #0078c8;
    color: #fff;
    padding: 0.2em 0.5em;
    font-size: 12px;
    border-radius: 4px;
    vertical-align: middle;
    line-height: 1;
    position: absolute;
    top: 10px;
    right: 0;
  }
}

// FontAwesome specific style. We do not use the complete FontAwesome css, only
// what we need.
.fa {
  display:inline-block;
  font-size:inherit;
  text-rendering:auto;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  cursor: default;

  &:before {
    font:normal normal normal 14px/1 FontAwesome;
  }

  &.fa-info-circle:before {
    content: '\f05a';
  }
  &.fa-certificate:before {
    content: '\f0a3';
  }
  &.fa-exclamation-triangle:before {
    content: '\f071';
  }

  &.warning {
    color: $color-primary-orange;
  }
}

// Medals, font-icon as background, number on foreground.
.bronze, .silver, .gold {
  position: relative;
  display: inline-block;
  width: 22px;
  line-height: 22px;
  font-size: 11px;
  text-align: center;
  color: #ffffff;
  top: -10px;
  z-index: 0;
  letter-spacing: 1px;
  font-family: 'Source Sans Pro', "Helvetica Neue", Arial, sans-serif;
  &:before {
    position: absolute;
    display: block;
    width: 22px;
    font-size: 22px;
    z-index: -1;
  }
  // Bigger version for the monitor.
  .view-compliancy-monitor & {
    width: 28px;
    line-height: 28px;
    font-size: 14px;
    margin: 0 2px;
    top: -3px;
    &:before {
      font-size: 28px;
      width: 28px;
    }
  }
}

.bronze {
  &:before {
    color: #cd7f32;
  }
}

.silver {
  &:before {
    color: #999999;
  }
}

.gold {
  &:before {
    color: #CCA700;
  }
}


span.progress_indicator {
  cursor: default;
}
.explanation_container {
  position: relative;
}

.explanation_trigger {
  cursor: pointer;
  text-decoration: none;
  font-size: 1.2em;
}

.usage-overview {
  position: relative;
}

div.hover_explanation,
div.progress_explanation {
  border: 1px solid #0078c8;
  background-color: white;
  margin-top: 10px;
  right: 20px;
  position: absolute;
  z-index: 1000;
  width: 300px;
  ul li {
    font-size: 12px;
    line-height: 1.4em;
  }
  span {
    font-size: 12px;
  }
}

div.hover_explanation {
  padding: 20px;
  line-height:1.2em;
  &.info-left {
    right: 0;
  }
  &.info-right {
    left: 0;
  }
}

div.progress_explanation {
  left: 50px;
}
tr.explanation_row td {
  background: #FCFCFC;
  span, p, li {
    font-size: 12px;
  }
}
.message {
  margin: 0 0 0.5em 0;
  &.new {
    .ui-accordion-header, h4 {
      color: $color-primary-orange;
    }
    .ui-accordion-header:before {
      content: "\f005";
      font-family: FontAwesome;
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -14px;
    }
  }
}

tr.message {
  table td {
    border: none;
  }
}

.new-messages {
  position: relative;
  padding-left: 1em;
  font-size: 1.1em;
  .count {
    background-color: $color-primary-orange;
    position: absolute;
    color: #fff;
    top: -7px;
    left: 30px;
    font-size: 0.7em;
    line-height: 1em;
    padding: 0.2em;
    border-radius: 5px;
  }
  &:after {
    font-family: FontAwesome;
    content: "\f003";
    color: #fff;
  }
}

.view-inbox {
  .views-field-mid {
    width: 15%;
  }
  .views-field-timestamp {
    width: 10%;
  }
  .message-status {
    position: relative;
    width: 15%;
  }
  .views-field-ops {
    width: 15%;
  }
  .is-done {
    .message-status {
      &:before {
        font-family: FontAwesome;
        content: "\f00c";
        color: #555;
        position: absolute;
        top: 15px;
        left: 100px;
        font-size: 1.5em;
      }
    }
  }
}

.modal-content table.ui-table {
  margin-bottom: 2em;
}

.ui-masthead.extra-spaced {
  padding-top: 1em;
  margin-bottom: 2em;
}

.view-supplier-suggestions {
  margin-top: 2em;
  min-height: 350px;
}

.view-organisation-supplier-suggestions,
.view-organisation-external-conn-suggestion {
  .views-field-decline-link {
    text-align: right;
  }
}

#swc-suggestion-supplier-add-suggestions-form {
  margin-top: 2em;
}

.dashboard-contained .tiles {
  min-height: 240px;
}

.announcement {
  padding:0.71em;
  img {
    vertical-align: middle;
    margin-top: -15px;
  }
  .highlight {
    color: $color-primary-orange;
  }
}

.medal-info {
  position: relative;
  top: -10px;
  font-size: 12px;
  &.needs-investigation {
    a {
      color: $color-primary-orange;
    }
  }
}

.ui-module-content.left-divider {
  padding-left: 1em;
  border-left: 2px solid #d2d8dc;
}
.ui-module-content.right-divider {
  padding-right: 1em;
  border-right: 2px solid #d2d8dc;
}

.view-swc-inconsistencies {
  th:last-child {
    width: 165px;
  }

  td table {
    margin: 0;
    border: 0;
  }

  td td {
    border-width: 0 0 1px 0;
    padding: 10px 0;
    &:last-child {
      text-align: right;
    }
  }

  td tr:first-of-type td {
    padding-top: 0;
  }

  td tr:last-of-type td {
    border: 0;
    padding-bottom: 0;
  }

  .teaser.shortened {
    color: #0078c8;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: #005995;
    }
  }
}

// Fix menu dropdown z-index. Make it appear in front of tabs.
@media (min-width: 768px) {
  .is-open .kingbar-dropdown {
    z-index: 99;
  }
}

// Fix menu dropdown z-index. Make it appear in front of tabs.
@media (min-width: 992px) {
  .kingbar-wide .kingbar-inner {
    padding: 0 1.6rem;
    width: auto;
    max-width: 1440px;
  }
}

// Start sticky footer styling.
html, body {
  height: 100%;
}

body {
  display: table;
  width: 100%;
}

header, #main, footer {
  display: table-row;
  > .wrap {
    max-width: 940px;
    margin: 0 auto;
  }
  > .fullscreen {
    max-width: 1440px;
  }
}

#main {
  height: 100%;
}

// Make sure admin menu is always on top.
#admin-menu, #admin-menu .dropdown {
  z-index: 999999;
}

#swc-plot-svg {
  width: 100%;
  border: solid 1px #0078c8;
  padding: 20px;
}

.svg-controls {
  text-align: right;
  margin-bottom: 20px;
  a {
    text-decoration: none;
  }
}

svg {
  overflow: hidden;
  display: inline;
  width: inherit;
  min-width: inherit;
  max-width: inherit;
  min-height: inherit;
  max-height: inherit;
  height: 1493px;
  a{
    text-decoration: none;
  }
}


.report-needs-check {
  color: $color-primary-blue;
  display: inline-block;
  margin-bottom: 5px;
}
.report-needs-check,
.ui-table {
  .warning {
    color: $color-primary-orange;
  }
  .error {
    background: $color-primary-orange;
    color: #fff;
    padding: 5px;
  }
}
div.tooltip span.workflow,
div.tooltip:hover span.workflow
{
  display: inline-block;
  padding: 0;
  background: transparent;
  border: none;
  margin-left: 0;
  position: static;
  box-shadow: none;
  margin-top: 0;
  width: auto;
  color: inherit;
}

div.tooltip.fa {
  position: relative;
  cursor: pointer;
  span {
    right: 0;
    margin-top: 10px;
    padding: 0;
    p {
      padding: 8px !important;
      font-size: 14px;
    }
  }
  &:before {
    color: #888;
    display: table-cell;
    padding-left: 3px;
    &:hover {
      color: $color-primary-blue;
    }
  }
}

// Warning, code repetition.
input[type="submit"].ui-btn,
button.ui-btn {
  border-radius: 3px;
  box-shadow: none;
  border: 1px solid $color-primary-blue;
  color: #fff;
    background-color: $color-primary-blue;
  &.primary {
    background-color: $color-primary-blue;
    color: #fff;
    display: block;
  }

  &:hover {
    color: #fff;
    background-color: lighten($color-primary-blue, 5%);
    box-shadow: none;

    &.ui-btn-danger {
      background-color: #b94a48;
    }

    &.primary {
      background-color: darken($color-primary-blue, 5%);
    }
  }
}

/*
 Model Migration Modal Form
 */
#modal-content .model-migration-form {
  min-width: 0;
  max-width: 600px;
  margin: 0;
  padding: 0;

  p {
    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    &.label {
      font-weight: bolder;
      margin-bottom: 0;
    }
  }

  .ui-btn {
    margin-right: 0.5em;
  }

  p .ui-btn {
    margin-top: 0;
  }

  input[type="submit"].ui-btn,
  button.ui-btn {
    background-color: #fff;
    color: $color-primary-blue;
  }

  p input[type="submit"].ui-btn.primary {
    display: inline-block;
  }

  .actions {
    clear: both;
    margin-bottom: 1em;
    &:after{
      content: '';
      display: table;
      width: 100%;
    }
  }

  .ui-combined {
    position: relative;
    display: block;
    > * {
      margin: 0;
    }
    .form-type-select {
      width: 80%;
      float: left;
    }
    select {
      padding: 2px;
    }
    input {
      width: 18%;
      float: right;
    }
  }
}

.ui-tabs {
  .migrate,
  .migrate.active {
    sup {
      background-color: $color-primary-orange;
      color: #fff;
      border: 0;
    }
  }
  sup {
    margin-left: 0.5em;
  }
}

#model-migration-info-agree {
  &:before {
    width: auto;
    padding: 0 0.75em;
    background-color: $color-primary-blue;
    color: #fff;
    border-radius: 3px;
    margin-right: 0.5em;
  }
}

.ui-toolbar {
  .ui-combined select {
    max-width: 300px;
  }
}

.view-gemma-uses-product-reference-component-migration {
  .views-field-supplier {
    width: 20%;
  }
  .views-field-views-conditional {
    width: 20%;
  }
}


table.treetable span.indenter {
  position: absolute;
}

table.treetable span {
  padding: 0;
}